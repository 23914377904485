/* global $ */

class Notifications
{
    $interval;

    constructor()
    {
        this.$interval = setInterval(this.get, 30000);
        $('body').on('click', '.notificationItem', this.acknowledge);
    }

    get()
    {
        $.ajax({
            url: '/?action=inspire-notifications/notifications/get',
            method: 'get',
            headers: {
                "X-CSRF-Token": window.Globals.csrfToken
            }
        }).done(data => {
            $('#notificationsContainer').html(data);
        });
    }

    acknowledge(e)
    {
        let notification = $(e.target);
        if (notification[0].nodeName === 'TD') {
            notification = notification.parents('tr');
        }

        const id = notification.data('id');
        if (id) {
            $.ajax({
                url: '/?action=inspire-notifications/notifications/acknowledge&id=' + id,
                method: 'get',
                headers: {
                    "X-CSRF-Token": window.Globals.csrfToken
                }
            });
        }
    }
}
new Notifications();
