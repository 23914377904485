/* global $ */

class App
{
    timer;
    bootstrap;
    menuMoving = false;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initTimer();
        $('body').css('opacity', 1);
        console.log('App initialized');
        this.bootstrapHistoryTabs();

        window.recentActivityMessages = [];
        setInterval(this.recentActivity, 5000);
    }

    recentActivity()
    {
        const el = $('#recentActivity'),
            elementType = el.data('elementType'),
            elementId = el.data('elementId');

        if (elementType && elementId) {
            $.ajax({
                url: '/?p=admin_L107FQ8BX/actions/elements/recent-activity',
                method: 'post',
                headers: {
                    'X-CSRF-Token': window.Globals.csrfToken
                },
                data: {
                    elementType, elementId
                },
                context: this
            }).done(data => {
                if (data.activity.length > 0) {
                    $.each(data.activity, (i, item) => {
                        if (!window.recentActivityMessages.includes(item.message)) {
                            window.recentActivityMessages.push(item.message);
                            el.append(item.message + '<br/>');
                        }
                    });
                }
            });
        }
    }

    bootstrapHistoryTabs()
    {
        $('.nav-tabs .nav-item a').click(function(e){
            window.history.pushState(null, null, e.target.href);
        });
    }

    initTimer()
    {
        import(/* webpackChunkName: "timer" */ './components/timer').then(chunk => {
            this.timer = new chunk.Timer;
        });
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone, .sortable, .pound-prefix').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addToast(message, type = "success", autoHide = true)
    {
        let _class = "align-items-center text-white border-0 mt-2 toast bg-" + type;
        let toast = $('<div class="' + _class + '" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="' + (autoHide ? 'true' : 'false') + '"><div class="d-flex"><div class="toast-body">' + message + '</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div></div>');
        toast.appendTo($('#global-messages'));
        this.initToasts(toast);
    }

    handleError(data, $elem, showToast = true)
    {
        if (data.status == 400) {
            let errors = {};
            if (data.responseJSON && data.responseJSON.errors) {
                errors = data.responseJSON.errors;
            }
            if (showToast) {
                this.addToast("Please fix the validation errors", "danger");
            }
            if ($elem) {
                this.showErrors($elem, errors);
            }
        } else {
            this.unexpectedError();
        }
    }

    showErrors($elem, errors)
    {
        let $item;
        $elem.find('.invalid-feedback').remove();
        for (let [name, list] of Object.entries(errors)) {
            $item = $elem.find('.field-' + name);
            for (let error of list) {
                $item.append('<div class="invalid-feedback">' + error + '</div>');
            }
        }
    }

    unexpectedError()
    {
        this.addToast("An unexpected error happened", "danger");
    }

    replaceTasks(jobId, $select)
    {
        $select.find('option:not([value=""])').remove();
        if (!jobId) {
            return;
        }
        return this.loadTasks(jobId).done((data) => {
            for (let i in data) {
                $select.append('<option value="' + i + '">' + data[i].name + '</option>');
            }
        });
    }

    loadTasks(jobId)
    {
        return $.ajax({
            url: '/?action=hh-jobs/jobs/job-tasks',
            method: 'post',
            data: {
                id: jobId
            },
            headers: {
                "X-CSRF-Token": window.Globals.csrfToken
            }
        });
    }
}

export default App;
